import { api } from '../index'

export interface Dashboard {
  date: string
  data: any[]
}

type Revenue = {
  registration: string | null
  uniform: string | null
}

type DashboardsResponse = {
  graphChart: Dashboard[]
  teamsCount: number
  registrationsCount: number
  revenue: Revenue
  playerCount: number
  coachCount: number
  refereeCount: number
}

export const dashboardsApi = api.injectEndpoints({
  endpoints: build => ({
    getDashboardData: build.query<DashboardsResponse, any>({
      query: () => {
        return {
          url: 'dashboards/',
        }
      },
      providesTags: ['dashboards'],
    }),
  }),
})

export const { useLazyGetDashboardDataQuery } = dashboardsApi
