import React from 'react'
import {
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts'

const COLORS = ['#008174', '#f5821f', '#c4037f']

export default function PieChart({
  playerCount = 0,
  coachCount = 0,
  refereeCount = 0,
}) {
  const data = [
    { name: 'Players', value: playerCount },
    { name: 'Coaches', value: coachCount },
    { name: 'Referees', value: refereeCount },
  ]

  return (
    <ResponsiveContainer width="95%" height={350}>
      <div style={{ position: 'relative', maxWidth: '350px', width: '95%' }}>
        <RechartsPieChart width={350} height={350}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </RechartsPieChart>
        <ul
          style={{
            position: 'absolute',
            bottom: '10px',
            left: 0,
            right: 0,
            listStyle: 'none',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {data.map((entry, index) => (
            <li
              key={`item-${index}`}
              style={{ color: COLORS[index % COLORS.length] }}
            >
              {entry.value} {entry.name}
            </li>
          ))}
        </ul>
      </div>
    </ResponsiveContainer>
  )
}
