import { Grid, Box, styled } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import MultipleAxes from 'manage-tritag/components/chart/multiple-axes'
import PieChart from 'manage-tritag/components/chart/pie-chart'
import Card from 'manage-tritag/components/dashboard/card'
import { useLazyGetDashboardDataQuery } from 'manage-tritag/services/api/endpoints/dashboards'
import { getFormattedDateYYMMDD } from 'manage-tritag/utils/date'
import Spinner from 'manage-tritag/components/loading/spinner'
import { numberWithCommas } from 'manage-tritag/utils/string'
import Layout from '../components/layout'

interface ChartEntry {
  name: string
  pv: number
  amt: number
}

const DashboardBox = styled(Box)`
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
`

const IndexPage = (): ReactElement => {
  const { user } = useAuth0()
  const [trigger, { isLoading, data }] = useLazyGetDashboardDataQuery()
  const [perDayChartData, setPerDayChartData] = useState<ChartEntry[]>([])

  useEffect(() => {
    if (user) {
      trigger(null)
    }
  }, [trigger, user])

  useEffect(() => {
    const getSevenDays = () => {
      const arr: ChartEntry[] = []

      if (data) {
        for (let i = 6; i >= 0; i--) {
          const day = new Date()
          day.setDate(day.getDate() - i)
          const dayLabel = getFormattedDateYYMMDD(day)

          const check = data.graphChart.filter(x => x.date === dayLabel)

          if (check && check.length) {
            arr.push({
              name: dayLabel,
              pv: check[0].data.length,
              amt: check[0].data.length,
            })
          } else {
            arr.push({
              name: dayLabel,
              pv: 0,
              amt: 0,
            })
          }

          setPerDayChartData(arr)
        }
      }
    }

    if (data) {
      getSevenDays()
    }
  }, [data, setPerDayChartData])

  return (
    <Layout label1="Dashboard" url="/">
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        <>
          <Grid container spacing={4} justifyContent="space-between">
            <Grid item xs={12} xl={8}>
              <DashboardBox px={4} pt={4} pb={8}>
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginBottom: '2rem',
                  }}
                >
                  Registrations (per day)
                </Typography>
                {data && <MultipleAxes data={perDayChartData} />}
              </DashboardBox>
            </Grid>
            <Grid item xs={12} xl={4}>
              <DashboardBox px={4} py={4}>
                <Typography
                  variant="h4"
                  component="h1"
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginBottom: '1rem',
                  }}
                >
                  Registrations (per type)
                </Typography>
                {data && (
                  <PieChart
                    playerCount={data?.playerCount}
                    coachCount={data?.coachCount}
                    refereeCount={data?.refereeCount}
                  />
                )}
              </DashboardBox>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} justifyContent="space-between">
                <Grid item xs={12} sm={6} xl={3}>
                  <DashboardBox>
                    <Card label="Teams registered" value={data?.teamsCount} />
                  </DashboardBox>
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                  <DashboardBox>
                    <Card
                      label="Participants registered"
                      value={data?.registrationsCount}
                    />
                  </DashboardBox>
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                  <DashboardBox>
                    <Card
                      label="Registration revenue"
                      value={`$ ${numberWithCommas(
                        Number(data?.revenue?.registration || 0).toFixed(2),
                      )}`}
                    />
                  </DashboardBox>
                </Grid>
                {typeof data?.revenue?.uniform !== 'undefined' && (
                  <Grid item xs={12} sm={6} xl={3}>
                    <DashboardBox>
                      <Card
                        label="Uniform revenue"
                        value={`$ ${numberWithCommas(
                          Number(data?.revenue?.uniform || 0).toFixed(2),
                        )}`}
                      />
                    </DashboardBox>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(IndexPage)
