import { Box, styled, Typography } from '@mui/material'

const StyledContainer = styled(Box)`
  /* ${({ theme }) => {
    return {
      [theme.breakpoints.down(800)]: {
        maxWidth: '100% !important',
      },
    }
  }} */
  padding: 2rem;
  padding-bottom: 2.5rem;
  max-width: 360px;
`

function Card({ label, value }: { label: string; value: any }) {
  return (
    <StyledContainer>
      <Typography
        variant="h4"
        component="h3"
        sx={{
          fontSize: '1.2rem',
          fontWeight: 'bold',
          marginBottom: '2rem',
        }}
      >
        {label}
      </Typography>
      <h1 style={{ textAlign: 'center', fontSize: '3rem' }}>{value}</h1>
    </StyledContainer>
  )
}

export default Card
